
import { Vue, Prop, Component, Watch } from 'vue-property-decorator';
import KeyValueRow from '@/components/form/KeyValueInput/KeyValueRow.vue';
import { FormFieldValidaiton, KeyValue, KeyValueAdditionalField } from '@/components/form/form.types';
import { ValidationObserver, ValidationProvider } from 'vee-validate';
import { randId } from '@/utils/components';
import { computed } from 'vue';

@Component({
  components: {
    KeyValueRow,
    ValidationObserver,
    ValidationProvider
  },
})
export default class KeyValueBody extends Vue {
  @Prop({ type: Array, required: true }) readonly value!: KeyValue[];
  @Prop({ type: String, default: '' }) readonly keyLabel!: string;
  @Prop({ type: String, default: '' }) readonly valueLabel!: string;
  @Prop({ type: String, default: 'text' }) keyType!: string;
  @Prop({ type: String, default: 'text' }) valueType!: string;
  @Prop({ type: Object, required: false }) keyValidation?: FormFieldValidaiton;
  @Prop({ type: Object, required: false }) valueValidation?: FormFieldValidaiton;
  @Prop({ type: Boolean }) readonly fieldLabels!: boolean;
  @Prop({type: Object, default: {}}) readonly additionalField!: KeyValueAdditionalField;
  @Prop({type: Object}) readonly groupValidation!: any;


  /**
   * This id and and the value watcher are required to maintain focus state
   * when removing the last character of the first row if it's the only row
   * because of the "removeEmpty" functionality.
   *
   * When the value is [], we add a placeholder row. If the id of that placeholder row is the same
   * as the removed row then focus will not be lost because the field won't completely re-render
   */
  id = randId();
  fieldWithWrongKeys: string[] = []

  @Watch('value', { immediate: true })
  updateId(): void {
    if (this.value.length) {
      this.id = this.value[0].id;
    }
  }

  get val(): KeyValue[] {
    return this.value.length
      ? this.value
      : [
          {
            key: '',
            value: '',
            id: this.id,
          },
        ];
  }

  get fieldsWIthWrongKey(): string[] {
    return this.fieldWithWrongKeys
  }

  updateFields(newValue: string[]) {
    this.fieldWithWrongKeys = [...newValue]
  }

  update(i: number, kv: KeyValue): void {
    // editing the first and only value in the list
    if (this.value.length === 1 && i === 0) {
      // and it's setting it empty
      if (kv.key === '' && kv.value === '') {
        // send an empty array instead.
        this.$emit('input', []);
        return;
      }
    }

    const v = this.value.slice();
    v[i] = kv;
    this.$emit('input', v);
  }

  remove(i: number): void {
    this.$emit('remove', i);
  }

  onBlur = ($event: any) => {
    this.updateFields(this.validateGroup())
    this.$emit('blur', $event)
  }

  onchange = ($event: any) => {
    this.$emit('change', $event)
    
  }

  validateGroup =  (): Array<string> => {
    this.fieldWithWrongKeys = [];
    const wrongItems: any = [];
    const valuesCopy = [...this.value]

    valuesCopy.forEach(item => {
      let temp = [];
      valuesCopy.forEach(innerItem => {
        if(item.key.toLocaleLowerCase() === innerItem.key.toLocaleLowerCase()) {
          temp.push(item.id);
        }
      });

      if (temp.length > 1) {
        wrongItems.push(item.id)
      } else {
        temp = []
      }
    })
    return wrongItems;
  }
}
